<template>
    <el-scrollbar class="seller-scrollbar">
        <el-card class="seller-card">
            <div slot="header" class="page-top">
                <span class="text">{{ $t('seller.customer.operateTitle') }}</span>
                <span class="tips">{{ $t('seller.customer.operateTip') }}</span>
            </div>
            <div class="page-section">
                <div class="section-title">
                    <span class="name"><i class="el-icon-s-claim"></i>{{ $t('seller.customer.operatSceneTitle') }}</span>
                    <span class="tips">{{ $t('seller.customer.operatSceneTip') }}</span>
                </div>
                <div class="section-crowd">
                    <div class="crowd-count">{{ $t('seller.customer.operatSceneText1') }} {{ pageCrowd.length }}</div>
                    <div class="crowd-list" style="margin-bottom: 24px;">
                        <div class="item" v-for="item in pageCrowd" :key="item.crowd_id">
                            <div class="item-main">
                                <div class="name">{{ item.title }}</div>
                                <div class="count">{{ item.total }} {{ $t('seller.unit.people') }}</div>
                                <div class="text">{{ $t('seller.customer.operatSceneText2') }}<span>{{ item.total_expect || '-' }}</span>{{ $t('seller.unit.people') }}</div>
                            </div>
                            <router-link :to="{ name: 'customerNonstandardDetail', query: {crowd_id: item.crowd_id }}" class="item-next"><i class="el-icon-right"></i></router-link>
                        </div>
                    </div>
                    <div class="crowd-count">
                        <span>{{ $t('seller.customer.operatSceneText3')}} {{ collectTotal }}</span>
                        <el-button type="text" @click="handleShowCrowd">{{ $t('seller.actions.addCollectCrowd') }}</el-button>
                        <el-button type="text" @click="$router.push({ name: 'customerCrowdmgr' })">{{ $t('seller.actions.viewAllCrowd') }}</el-button>
                    </div>
                    <div class="crowd-list">
                        <div class="item" v-for="item in pageCollect" :key="item.crowd_id">
                            <div class="item-main">
                                <div class="name">{{ item.title }}</div>
                                <div class="count">{{ item.total > 0 ? item.total + ' ' + $t('seller.unit.people'): '-' }}</div>
                                <div class="text">{{ $t('seller.customer.operatSceneText4') }}<span>{{ item.total_expect || '-' }}</span>{{ $t('seller.unit.people') }}</div>
                            </div>
                            <router-link :to="{ name: 'customerNonstandardDetail', query: {crowd_id: item.crowd_id }}" class="item-next"><i class="el-icon-right"></i></router-link>
                        </div>
                        <div class="item item-upload" v-if="collectTotal > 0" @click="handleShowCollect"><i class="el-icon-circle-plus"></i>{{ $t('seller.customer.operatSceneText5') }}</div>
                    </div>
                    <el-dialog :title="$t('seller.dialogTitle.addCollect')" :visible.sync="dialogVisibleCrowd" width="950px" custom-class="seller-dialog">
                        <div class="dialog-crowd">
                            <el-checkbox-group v-loading="crowdLoading" v-model="checkCrowdIds" class="crowd-group">
                                <el-checkbox v-for="item in crowdList" :key="item.crowd_id" :label="item.crowd_id">
                                    <div class="name">{{ item.title }}</div>
                                    <div class="count">{{ item.predicts || '-' }}</div>
                                </el-checkbox>
                            </el-checkbox-group>
                            <pagination v-show="crowdTotal > 0" :total="crowdTotal" :page.sync="crowdCurr" :limit.sync="crowdSize" @pagination="getCrowdList"></pagination>
                        </div>
                        <div slot="footer" class="dialog-footer">
                            <div class="text">{{ $t('seller.actions.selected') }} {{ checkCrowdIds.length }} {{ $t('seller.customer.operatSceneText6') }}</div>
                            <div>
                                <el-button type="primary" @click="handleCollectCrowd" round>{{ $t('seller.actions.collectCrowd') }}</el-button>
                                <el-button @click="dialogVisibleCrowd = false" round>{{$t('seller.actions.cancel') }}</el-button>
                            </div>
                        </div>
                    </el-dialog>
                    <el-dialog :title="$t('seller.dialogTitle.collectManage')" :visible.sync="dialogVisibleCollect" width="950px" custom-class="seller-dialog">
                        <div class="dialog-crowd">
                            <div class="crowd-page" v-loading="collectLoading">
                                <div class="item" v-for="item in collectList" :key="item.crowd_id">
                                    <div class="name">{{item.title}}</div>
                                    <div class="count">{{item.predicts || '-'}}</div>
                                    <div class="cancel" @click="handleCancelCollect(item.crowd_id)">{{ $t('seller.actions.uncollect') }}</div>
                                </div>
                            </div>
                            <pagination v-show="collectTotal > 0" :total="collectTotal" :page.sync="collectCurr" :limit.sync="collectSize" @pagination="getCollectList"></pagination>
                        </div>
                    </el-dialog>
                </div>
            </div>
        </el-card>
    </el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCustomCrowd, fetchCustomCrowdList, fetchCollectCrowd, collectCrowd, uncollectCrowd  } from "@/api/seller/customer"
export default {
	components: { Pagination },
    data() {
        return {
            pageCrowd: [],
            pageCollect: [],
            dialogVisibleCrowd: false,
            crowdLoading: false,
            crowdList: [],
            crowdCurr: 1,
            crowdSize: 16,
            crowdTotal: 0,
            checkCrowdIds: [],
            dialogVisibleCollect: false,
            collectLoading: false,
            collectList: [],
            collectCurr: 1,
            collectSize: 16,
            collectTotal: 0
        }
    },
    created () {
        this.getData()
        this.getCrowdList()
        this.getCollectList()
    },
    methods: {
        async getData() {
            const { data } = await fetchCustomCrowd()
            this.pageCrowd = data.crowds
            this.pageCollect = data.collect
        },
        handleShowCrowd() {
            this.crowdCurr = 1
            this.crowdList = []
            this.checkCrowdIds = []
            this.getCrowdList()
            this.dialogVisibleCrowd = true
        },
        getCrowdList() {
            this.crowdLoading = true
            const params = {
				page_index: (this.crowdCurr - 1) * this.crowdSize,
				page_size: this.crowdSize
            }
            fetchCustomCrowdList(params).then(response => {
                const { data } = response
                this.crowdList = data.list
                this.crowdTotal = parseInt(data.count)
                this.crowdLoading = false
            })
        },
        handleShowCollect() {
            this.collectCurr = 1
            this.collectList = []
            this.getCollectList()
            this.dialogVisibleCollect = true
        },
        getCollectList() {
            this.collectLoading = true
            const params = {
				page_index: (this.collectCurr - 1) * this.collectSize,
				page_size: this.collectSize
            }
            fetchCollectCrowd(params).then(response => {
                const { data } = response
                this.collectList = data.list
                this.collectTotal = parseInt(data.count)
                this.collectLoading = false
            })
        },
        handleCollectCrowd() {
            if(!this.checkCrowdIds.length > 0) {
                this.$message.error(this.$t('seller.errorMsg.selectCrowd'))
                return false
            }
            const params = {
				crowd_ids: this.checkCrowdIds.join(',')
			}
            collectCrowd(params).then(() => {
                this.$message.success(this.$t('seller.successMsg.collect'))
                this.getData()
                this.getCollectList()
                this.checkCrowdIds = []
                this.dialogVisibleCrowd = false
            })
        },
        handleCancelCollect(id) {
            uncollectCrowd({crowd_id:id}).then(() => {
                this.getData()
                this.getCollectList()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.seller-card {
    .page-top {
        .text {
            font-size: 18px;
            color: #3a3a3a;
            margin-right: 4px;
        }
        .tips {
            font-size: 14px;
            color: #999;
        }
    }
    .page-section {
        .section-title {
            margin-bottom: 24px;
            .name {
                color: #111;
                font-size: 14px;
                [class^=el-icon-] {
                    margin-right: 8px;
                    font-size: 18px;
                    color: $--seller-primary;
                }
            }
            .tips {
                margin-left: 26px;
                font-size: 14px;
                color: #666666;
            }
        }
        .section-crowd {
            padding: 14px 28px 24px;
            border-radius: 12px;
            background-color: rgba(0,145,255,.07);
            .crowd-count {
                font-size: 12px;
                margin-bottom: 18px;
                .el-button--text {
                    margin-left: 16px;
                }
            }
            .crowd-list {
                display: flex;
                .item {
                    width: calc((100% - 120px) / 6);
                    height: 90px;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 5px;
                    margin-right: 24px;
                    background-color: #f8f8f8;
                    .item-main {
                        flex: 1;
                        width: 0;
                        padding: 18px 12px 0;
                        .name {
                            height: 16px;
                            line-height: 16px;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .count {
                            margin-top: 5px;
                            font-weight: 700;
                            color: #333;
                            font-size: 16px;
                            line-height: 20px;
                        }
                        .text {
                            margin-top: 10px;
                            font-size: 12px;
                            line-height: 14px;
                            color: #333;
                        }
                    }
                    .item-next {
                        flex-shrink: 0;
                        width: 36px;
                        border-radius: 5px;
                        border: 1px dashed $--seller-primary;
                        background-color: #eff5ff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        [class^=el-icon-] {
                            font-size: 18px;
                            border-radius: 50%;
                            color: $--seller-primary;
                            border: 1px solid $--seller-primary;
                            border-left-color: transparent;
                        }
                    }
                    &.item-upload {
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        color: #333;
                        cursor: pointer;
                        [class^=el-icon-] {
                            font-size: 28px;
                            margin-right: 6px;
                            color: $--seller-primary;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.dialog-crowd {
    .crowd-page {
        display: flex;
        flex-wrap: wrap;
        .item {
            width: 200px;
            height: 110px;
            padding: 18px;
            border-radius: 6px;
            margin-bottom: 18px;
            margin-right: 18px;
            box-sizing: border-box;
            background-color: #f8f8f8;
            .name {
                font-size: 14px;
                line-height: 1;
                overflow: hidden;
                margin-bottom: 9px;
            }
            .count {
                color: #333;
                font-size: 14px;
                line-height: 1.5;
            }
            .cancel {
                margin-top: 19px;
                font-size: 14px;
                color: $--seller-primary;
                cursor: pointer;
            }
        }
    }
}
::v-deep .el-dialog {
    &.uu-dialog {
        .el-dialog__body {
            padding: 0 20px;
        }
    }
}
::v-deep .el-checkbox-group {
    &.crowd-group {
        display: flex;
        flex-wrap: wrap;
        .el-checkbox {
            width: 200px;
            height: 76px;
            padding: 18px;
            border-radius: 6px;
            margin-bottom: 18px;
            margin-right: 18px;
            box-sizing: border-box;
            background-color: #f8f8f8;
            .el-checkbox__input {
                top: 0;
                right: 0;
                position: absolute;
            }
            .el-checkbox__label {
                padding-left: 0;
                .name {
                    font-size: 14px;
                    line-height: 1;
                    overflow: hidden;
                    margin-bottom: 9px;
                }
                .count {
                    color: #333;
                    font-size: 14px;
                    line-height: 1.5;
                }
            }
        }
    }
}
.dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
        font-size: 14px;
        color: $--seller-primary;
    }
}
</style>